<template>
  <!-- 待学习 -->
  <div class="toLearn">
    <van-nav-bar
      :title="ispts == 1?'公共课程':'实施课程'"
      left-text
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
      :fixed="true"
    />
    <div class="fixedH" style="height: 1.22667rem"></div>
    <van-search
      v-model="searchVal"
      placeholder="请输入搜索关键词"
      @input="onSearch"
      class="fefefefef"
    />
    <div class="fixedH" style="height: 1.22667rem"></div>
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="$t('toLearn.LearnTxt2')"
      @load="onLoad"
    >
      <van-collapse v-model="activeNames">
        <van-collapse-item :name="key+'sskc'" v-show="ispts == 0" v-for="(item, key) in list2" :key="key">
          <template #title>
            <div style="display: flex; align-items: center">
              <img
                style="width: 24px; height: 24px"
                src="@/assets/img/shishikecheng.png"
                alt
              />
              <span
                style="
                  font-size: 15px;
                  color: #0f6ae5;
                  font-weight: bold;
                  margin-left: 6px;
                "
                >{{ item.coursetype }}</span
              >
            </div>
          </template>
          <van-cell
            v-for="(value, index) in item.data" 
            :key="index"
            class="examList"
            @click="examinInfo(value, index)"
          >
            <div class="left-img">
              
              <img src="@/assets/img/dwedewweddw.png" alt />
            </div>
            <div class="info">
              <div class="infoExamIcon" v-if="value.isfinish">
                <img src="@/assets/img/trained.png" alt />
              </div>
              <!-- <p class="examName">招聘类-文秘招聘考试</p> -->
              <div class="toptop">
                <span class="courseName">{{ value.coursename }}</span>
              </div>
              <span class="lecturerName"
                >{{ $t("toLearn.LearnTxt4") }}{{ value.teacher_empname }}</span
              >
              <div class="footer">
                <span class="date"
                  >{{ value.studybt }} - {{ value.studyet }}</span
                >
              </div>
              <div class="footer">
                <span class="date">
                  {{ $t("toLearn.LearnTxt5") }}{{ value.coursehour
                  }}{{ $t("toLearn.LearnTxt6") }}
                </span>
                <div class="right">
                  <span class="score"
                    >{{ value.coursecredit }}{{ $t("toLearn.LearnTxt3") }}</span
                  >
                </div>
              </div>
            </div>
          </van-cell>
        </van-collapse-item>
        
        <van-collapse-item :name="key+'ggkt'" style="margin-top: 15px;" v-show="ispts == 1" v-for="(item, key) in list1" :key="key">
          <template #title>
            <div style="display: flex; align-items: center">
              <img
                style="width: 24px; height: 24px"
                src="@/assets/img/gonggongkecheng.png"
                alt
              />
              <span
                style="
                  font-size: 15px;
                  color: #e87110;
                  font-weight: bold;
                  margin-left: 6px;
                "
                >{{ item.coursetype }}</span
              >
            </div>
          </template>
          <van-cell
            v-for="(value, index) in item.data" 
            :key="index"
            class="examList"
            @click="examinInfo(value, index)"
          >
            <div class="left-img">
              <img src="@/assets/img/dweddwedwddew.png" alt />
            </div>
            <div class="info">
              <div class="infoExamIcon" v-if="value.isfinish">
                <img src="@/assets/img/trained.png" alt />
              </div>
              <!-- <p class="examName">招聘类-文秘招聘考试</p> -->
              <div class="toptop">
                <span class="courseName">{{ value.coursename }}</span>
              </div>
              <span class="lecturerName"
                >{{ $t("toLearn.LearnTxt4") }}{{ value.teacher_empname }}</span
              >
              <div class="footer">
                <span class="date"
                  >{{ value.studybt }} - {{ value.studyet }}</span
                >
              </div>
              <div class="footer">
                <span class="date">
                  {{ $t("toLearn.LearnTxt5") }}{{ value.coursehour
                  }}{{ $t("toLearn.LearnTxt6") }}
                </span>
                <div class="right">
                  <span class="score"
                    >{{ value.coursecredit }}{{ $t("toLearn.LearnTxt3") }}</span
                  >
                </div>
              </div>
            </div>
          </van-cell>
        </van-collapse-item>
      </van-collapse>
    </van-list>
  </div>
</template>

<script>
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
import { getStudyCourseList } from "@api/wxpx.js";
export default {
  data() {
    return {
      userInfo,
      isLoading: false,
      list: [],
      list1: [],
      list2: [],
      loading: false,
      finished: false,
      activeNames: [
        "0sskc", "1sskc","2sskc","3sskc","4sskc","5sskc","6sskc","7sskc","8sskc","9sskc","10sskc",
        "0ggkt","1ggkt","2ggkt","3ggkt","4ggkt","5ggkt","6ggkt","7ggkt","8ggkt","9ggkt","10ggkt",
      ],
      ispts:this.$route.query.ispts,
      searchVal:""
    };
  },
  methods: {
    // 搜索
    onSearch(val) {
      if (val) {
        let arr1 = [];
        let arr2 = [];
        this.list = this.list.filter(item => {
          return item.coursename.indexOf(val) != -1
        })
        const data = this.list;
        data.forEach((item, key) => {
          if (item.ispts) {
            arr1.push(item);
          } else {
            arr2.push(item);
          }
        });
        this.list1 = this.getNewData(arr1,'coursetype')
        this.list2 = this.getNewData(arr2,'coursetype');
        console.log(this.list)
      } else {
        this.onLoad()
      }
    },
    examinInfo(item, key) {
      window.sessionStorage.setItem("v8_study_data", JSON.stringify(item));
      window.localStorage.setItem("learningCoursesDataKey" + item.autoid, 0);
      window.localStorage.setItem("learningCoursesDataIndex" + item.autoid, 0);
      this.$router.push({
        path: "/learningCourses",
        query: {
          mautoid: item.autoid,
        },
      });
    },
    onClickLeft() {
      if (window.CloundEntid == "115") {
        window.location.href = "http://hyclound.cn/hgvis_115_h5/pc2022_5.html";
      } else {
        this.$router.push("/home");
      }
    },
    onClickRight() {},
    groupData(arr, fun) {
			const groups = {}
      arr.forEach((el) => {
      const group = fun(el)
        groups[group] = groups[group] || []
        groups[group].push(el)
      })
      return Object.keys(groups).map((group) => {
        // 更改data的数据结构  可以改变新数据的结构 data即为newData的数组中每个数据的结构
        let data = {
          coursetype: group,
          data: groups[group]
        }
        return data
      })
		},
    getNewData(oldData, prop){
      const newData = this.groupData(oldData, (item) => {
        return item[prop]
      })
      return newData
    },
    onLoad() {
      getStudyCourseList({ 
        empid: this.userInfo.empid,
        ispts: this.ispts == 0?false:true
      }).then((res) => {
        console.log(res.data);
        if (res.iserror == 0) {
          let arr1 = [];
          let arr2 = [];
          const data = res.data;
          data.forEach((item, key) => {
            if (item.ispts) {
              arr1.push(item);
            } else {
              arr2.push(item);
            }
          });
          
          this.list = res.data;
          this.list1 = this.getNewData(arr1,'coursetype')
          this.list2 = this.getNewData(arr2,'coursetype');
          this.loading = false;
          this.finished = true;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.fefefefef{
  position: fixed;
    left: 0;
    top: 1.22667rem;
    z-index: 99;
    width: 100%;
}
.toLearn {
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .left-img {
    position: relative;
    width: 342px;
    height: 190px;
    overflow: visible;
    background: #ecf2ff;
    opacity: 1;

    text-align: center;
    margin-right: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      display: block;
      max-height: 100% !important;
    }
  }
  .examList {
    padding: 0 !important;
    margin-bottom: 30px !important;
    .van-cell__value {
      display: flex;

      .info {
        flex: 1;
        position: relative;
        .infoExamIcon {
          position: absolute;
          right: 0;
          top: 0;
          z-index: 99999;
          width: 90px;
          img {
            width: 90px;
          }
        }
        .lecturerName {
          color: #999999;
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 400;
        }
        .toptop {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          .courseName {
            width: 78%;
            line-height: 1.1;
            margin: 0;
            font-size: 30px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
          }
          .right {
            .score {
              font-size: 30px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 27px;
              color: #2b8df0;
            }
            .duration {
              font-size: 24px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 27px;
              color: #333333;
            }
          }
        }
        .footer {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .date {
            color: #999999;
            font-size: 20px;
          }
          .right {
            .score {
              font-size: 30px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 27px;
              color: #2b8df0;
            }
            .duration {
              font-size: 24px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 27px;
              color: #333333;
            }
          }
        }
      }
    }
  }
}
</style>
